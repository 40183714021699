<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
        <router-link
          :to="{
            name: 'studentExamResults'
          }"
          class="btn btn-primary mb-3"
        >
          <i class="flaticon2-right-arrow"></i>
          {{ $t("GENERAL.BACK") }}
        </router-link>
        <KTPortlet :title="title">
          <template v-slot:body>
            <div>
              <b-overlay rounded="sm">
                <b-row>
                  <b-col md="3" lg="3">
                    <b-form-group :label="$t('GENERAL.SELECT_SEMESTER')">
                      <b-form-select
                        v-model="student_class_selected"
                        :options="student_classes"
                        @change="getExamMarksErrorReportResponse"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row v-if="isBusy" class="text-center text-danger my-3">
                  <b-spinner variant="danger"></b-spinner><br />
                  <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                </b-row>
                <b-row v-else>
                  <b-row v-if="exam_mark_error_responses.length !== 0">
                    <template
                      v-for="(exam_mark_error_response,
                      i) in exam_mark_error_responses"
                    >
                      <b-row :key="`item-${i}`" class="mr-3 ml-3 col-lg-12">
                        <HandlingExamComplaintsForum
                          class="col-lg-6 col-md-6 col-sm-12"
                          v-for="error_response in exam_mark_error_response"
                          :key="error_response.id"
                          :class_id="error_response.class_id"
                          :subject_name="error_response.subject_name"
                          :chance="error_response.chance"
                          :description="error_response.description"
                          :response="error_response.response"
                          :description_date="error_response.description_date"
                          :response_date="error_response.response_date"
                        ></HandlingExamComplaintsForum>
                      </b-row>
                    </template>
                  </b-row>
                  <b-row v-else class="mr-2 ml-3">
                    <b-alert
                      variant="info"
                      show
                      class="text-justify text-left"
                      >{{
                        $t("HANDLING_EXAM_COMPLAINTS.NO_COMPLAINTS")
                      }}</b-alert
                    >
                  </b-row>
                </b-row>
              </b-overlay>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";
import HandlingExamComplaintsForum from "@/views/partials/widgets/HandlingExamComplaintsForum.vue";

import { mapState } from "vuex";
import { getUserPhoto } from "@/common/jwt.service";

export default {
  name: "HandlingExamComplaints",
  components: {
    KTPortlet,
    HandlingExamComplaintsForum
  },
  data() {
    return {
      title: this.$t("GENERAL.COMPLAINTS_HANDLING"),
      exam_mark_error_responses: [],
      student_classes: [],
      student_class_selected: 0,
      isBusy: true,
      no_response: this.$t("HANDLING_EXAM_COMPLAINTS.NO_RESPONSE")
    };
  },
  methods: {
    getExamMarksErrorReportResponse() {
      this.isBusy = true;
      this.exam_mark_error_responses = [];
      let filters = {
        student_class_id: this.student_class_selected
      };
      ApiService.post("student/marks-error-response", filters).then(
        ({ data }) => {
          this.chunkResponses(data.exam_mark_error_responses, 2);
          this.isBusy = false;
        }
      );
    },
    chunkResponses(array, length) {
      let i = 0;
      let n = array.length;
      while (i < n) {
        this.exam_mark_error_responses.push(array.slice(i, (i += length)));
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("GENERAL.COMPLAINTS_HANDLING"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("student/enrolled-classes/exam-results").then(({ data }) => {
      this.student_classes = data.student_classes;
      this.student_class_selected =
        data.student_classes[0] !== undefined
          ? this.student_classes[0].value
          : 0;
      this.getExamMarksErrorReportResponse();
    });
  },
  computed: {
    ...mapState({
      isPhotoLoaded: state => state.auth.isPhotoLoaded
    }),
    userPhoto() {
      if (this.isPhotoLoaded) {
        return getUserPhoto();
      }
      return null;
    }
  }
};
</script>
