<template>
  <div style="color: black;">
    <div
      class="p-3 mt-4"
      style="border: 2px solid #bbb9b9; border-radius: 10px;"
    >
      <div class="d-flex flex-column mb-3 align-items-start">
        <div class="d-flex align-items-center mb-2">
          <div class="mr-3">
            <span class="kt-media kt-media--circle kt-media--md">
              <img :src="userPhoto" alt="image" />
            </span>
          </div>
          <div>
            <h5 class="font-weight-bold">
              {{ $t("GENERAL.YOU") }}
            </h5>
            <span class="text-muted">{{ description_date }}</span>
            <span class="pull-left ml-3">
              {{ $t("GENERAL.CHANCE") }} &nbsp;{{ chance }}
              {{ $t("GENERAL.SUBJECT") }} {{ subject_name }}
            </span>
          </div>
        </div>
        <div
          class="rounded p-4 kt-bg-light-primary font-weight-bold text-left w-100"
        >
          {{ description }}
        </div>
      </div>
      <div class="d-flex flex-column mb-3 align-items-start">
        <div class="d-flex align-items-center" v-if="this.response !== null">
          <div class="mr-3">
            <span class="kt-media kt-media--circle kt-media--md">
              <img src="assets/media/logos/logo-dawat.png" alt="image" />
            </span>
          </div>
          <div>
            <h5 class="font-weight-bold">{{ $t("GENERAL.EXAM_COMMITTEE") }}</h5>
            <span class="text-muted" v-if="this.response !== null">{{
              response_date
            }}</span>
          </div>
        </div>
        <div
          class="rounded p-4 kt-bg-light-success font-weight-bold text-left w-100"
          v-if="this.response !== null"
        >
          {{ response }}
        </div>
        <div
          class="rounded p-4 kt-bg-light-warning font-weight-bold text-left w-100"
          v-else
        >
          {{ $t("HANDLING_EXAM_COMPLAINTS.NO_RESPONSE") }}
        </div>
        <span class="pull-left mt-3">
          <b-button
            variant="dark"
            :to="{
              name: 'studentExamResults',
              params: {
                class_id: class_id
              }
            }"
            >{{ $t("HANDLING_EXAM_COMPLAINTS.VIEW_RESULTS") }}
            <i class="flaticon2-checking"></i
          ></b-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getUserPhoto } from "@/common/jwt.service";

export default {
  name: "HandlingExamComplaintsForum",
  props: [
    "class_id",
    "subject_name",
    "chance",
    "description",
    "response",
    "description_date",
    "response_date"
  ],
  computed: {
    ...mapState({
      isPhotoLoaded: state => state.auth.isPhotoLoaded
    }),
    userPhoto() {
      if (this.isPhotoLoaded) {
        return getUserPhoto();
      }
      return null;
    }
  }
};
</script>
